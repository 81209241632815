<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employees Payroll</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Employees Payroll</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            
            <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Month</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.month"
                                :options="months"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="2" class="mb-1rem row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <button class="btn btn-primary" @click="onSelection()">
                            Filter
                        </button>
                    </div>
                    <div class="ml-2 col-lg-4 col-md-4 col-sm-4 text-left">
                        
                    </div>
                </b-col>
             
                <b-col lg="4" class="mb-1rem row " >
                    <div class="col-lg-11 col-md-11 col-sm-11 text-right">
                        <router-link
                            :to="{name:'payroll-export'}"
                            style="float:right"
                        >   
                            <b-button variant="primary"> <font-awesome-icon icon="table" class="text-right"> </font-awesome-icon> Payroll Exports </b-button>
                        </router-link> 
                    </div>
                    <div class="col-lg-1 col-md-11 col-sm-11 text-right" v-if="hasPermission('CAN_DOWNLOAD_PAYROLL')">
                        <b-button @click="downloadPayroll" variant="primary" v-b-tooltip.hover title="Download Payroll"  class="text-left"> 
                            <font-awesome-icon icon="download"> </font-awesome-icon>
                        </b-button>
                    </div>
                    
                </b-col>
                
            </b-row>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="items"
                    @sort-changed="customSorting"
                    :fields="fields"
                    no-local-sorting
                    responsive
                    @row-clicked="rowClick"
                    tbody-tr-class="pointer-cursor"
                >
                    <template v-slot:cell(emp_id)="row">
                        {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                    </template>
                    <template v-slot:cell(month)="row">
                        {{getMonthName(row.item.month)}}
                    </template>
                    <template v-slot:cell(generated_by)="row">
                        <div v-if="row.item.generated_by">{{row.item.generated_by_employee.full_name}}</div>
                        <div v-else>System</div>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <div>
                            <router-link
                                :to="{name:'employees-payroll-details', params: { emp_id:row.item.emp_id, id: row.item.id }}"
                            >   
                                <button class="btn-sm btn rounded-circle btn-info mr-2" v-b-tooltip.hover title="View Payslip Details"> 
                                    <font-awesome-icon icon="eye" size="1x"></font-awesome-icon>
                                </button>
                            </router-link>
                        </div>
                    </template>
                </b-table>
                <b-row align-h="between" v-if="totalRows">
                    <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                    <b-col lg="4" class="p-3">
                        <nav aria-label="Page navigation" class="justify-content-center">
                            <ul class="pagination float-right">
                                <li class="page-item">
                                    <span
                                        class="page-link"
                                        v-on:click="pagination(urlBody.page - 1)"
                                        v-if="urlBody.page > 1"
                                        style="cursor:pointer"
                                    >
                                        <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                    </span>
                                    <span
                                        class="page-link text-muted"
                                        style="cursor:not-allowed !important;"
                                        v-else
                                    >
                                        <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                    </span>
                                </li>
                                <li class="page-item">
                                    <span
                                        class="page-link text-muted"
                                        style="cursor:not-allowed !important;"
                                        v-if="urlBody.page == lastPage"
                                    >
                                        Next
                                        <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                    </span>
                                    <span
                                        class="page-link"
                                        v-else
                                        v-on:click="pagination(urlBody.page +1)"
                                        style="cursor:pointer"
                                    >
                                        Next
                                        <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </b-col>
                </b-row>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                year: new Date().getFullYear(),
                sort_by: "",
                order: "",
                month: '',
                page: "",
                per_page: "",
            },
            api_error: "",
            fields: [
                { key: "emp_id", sortable: true,label:"Employee"},
                { key: "year"},
                { key: "month"},
                { key: "gross_salary",sortable: true},
                {key: "net_pay",sortable: true},
                {key: "generated_by", label:'Generated By'},
                {key: "created_at", sortable: true,label:'Generated At'},
                {key: "actions", label:"Actions"}
            ],
            months: [
                { value: "01", text: 'January' },
                { value: "02", text: 'Febuary' },
                { value: "03", text: 'March' },
                { value: "04", text: 'April' },
                { value: "05", text: 'May' },
                { value: "06", text: 'June' },
                { value: "07", text: 'July' },
                { value: "08", text: 'August' },
                { value: "09", text: 'September' },
                { value: "10", text: 'October' },
                { value: "11", text: 'November' },
                { value: "12", text: 'December' }
            ],
            items: null,
            filter: null,
            access: true,
            years: [],
            total_years:3,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            totalRows:"",
            pageOptions: [10, 25, 50, 100],
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_PAYROLL");
        this.getPreviousMonth();
        this.getYears();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.getPayroll(urlBody);
        },

        getPreviousMonth(){
            var  months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
            var d = new Date();
            let get_month = months[d.getMonth()];
            if (get_month == 1){
                this.urlBody.year = (new Date()).getFullYear() -1;
                this.urlBody.month = 12;
            } else {
                var dd = get_month-1;
                this.urlBody.month = dd <10 ? '0'+dd : dd ;
            }
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        getYears() {
            const currentYear = (new Date()).getFullYear()+1;
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
            this.onPageLoad();
        },

        getDefaultFilters() {
            let api_params = {
                year: this.urlBody.year,
                month: this.urlBody.month
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody); 
            this.urlBody.year = api_params.year;
            this.urlBody.month = api_params.month;
            this.getPayroll(api_params);
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        getPayroll(params) {
              
            this.items = [];
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_all_payroll, query).then(response => {
                const result = response.data.data;
                this.items = result.data;
                this.urlBody.page = result.current_page;
                this.urlBody.per_page = result.per_page;
                this.lastPage = result.last_page;
                this.totalRows = result.total;
                this.updateProgressBar(true);
                this.$router.replace({
                    path: this.$route.params[0],
                    query: params
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        onSelection() {
            this.getPayroll(this.getDefaultFilters());
        },

        rowClick(item){
            this.$router.push({name:'employees-payroll-details', params: {emp_id:item.emp_id, id:item.id}});
        },

        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getPayroll(newQuery);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.deleted = "";
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: newQuery
                })
                .catch(err => {
                    console.error(err);
                });
            this.getPayroll(newQuery);
        },

        downloadPayroll() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            var date = new Date(this.urlBody.year, parseInt(this.urlBody.month)-1);
            let d = new Date();
            let file_name = "Payroll-" + date.toLocaleString('default', { month: 'short' }) + "-" + this.urlBody.year + "-" + d.getDate() + '' + (d.getMonth()+1) + '' + d.getFullYear() + '' + d.getHours() + "" + d.getMinutes() + '' + d.getSeconds();
            query["params"] = {
                year: this.urlBody.year,
                month: this.urlBody.month,
            };
            query["responseType"] = 'blob';
            this.axios.get(this.$api.download_payroll, query).then(response => {
                var url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file_name+'.xlsx');
                document.body.appendChild(link);
                link.click();
                var query = this.getAuthHeaders();
                query["responseType"] = 'JSON';
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        getCurrentDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            return today;
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.pointer-cursor {
    cursor:pointer !important;
}

</style>